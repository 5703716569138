import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasourceFactory } from 'src/app/dashboard/models/datasource-factory';
import { DatasourceType } from 'src/app/dashboard/models/datasource-type';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/dynamic-api';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { SelectOption } from 'src/app/settings/settings.model';
import { Device } from 'src/app/shared/models/device';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog.component';
import { SelectDialog } from '../select-dialog/select-dialog.component';
import { SimpleInputDialog } from '../simple-input-dialog/simple-input-dialog.component';

@Component({
    templateUrl: './device-dashboard-dialog.component.html',
})
export class DeviceDashboardDialog implements OnInit {
    newTab = new FormControl();
    form = new FormGroup({
        dashboardId: new FormControl(null, Validators.required),
        newTab: this.newTab,
    });
    device: Device;
    options: SelectOption[];

    get id() {
        return this.form.get('dashboardId');
    }

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: { device: Device },
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public snackBar: SnackBarService,
        private api: ApiService,
        private dialog: DialogService,
    ) {}

    async ngOnInit() {
        await ensureLoaded([this.api.dashboards]);
        this.device = this.data.device;
        this.options = this.api.dashboards
            .current({
                sort: 'name',
            })
            .filter((d) => d.deviceIds.includes(this.device.id))
            .map((dashboard) => ({
                value: dashboard.id,
                name: dashboard.name,
            }));
        // Select the first one in the list
        if (this.options.length > 0) {
            this.form.patchValue({ dashboardId: this.options[0].value });
        }
    }

    // The user wants to create a new dashboard.
    async create() {
        this.dialogRef.close();
        // Get all the dashboards of the model.
        const dashboards = this.api.dashboards
            .current({ modelId: this.device.modelId })
            // Filter by dashboards that only have one device datasource.
            .filter(
                ({ content }) =>
                    content.datasources.filter(
                        (datasource) =>
                            datasource.type == DatasourceType.DEVICE,
                    ).length === 1,
            )
            .sort((a, b) => a.name.localeCompare(b.name));
        const options = [
            { value: 0, name: 'dashboard.template.empty' },
            ...dashboards.map((dashboard) => ({
                value: dashboard.id,
                name: dashboard.name,
            })),
        ];
        const dashboardId = await this.dialog.open(SelectDialog, {
            data: {
                title: 'dashboard.template',
                description: 'dashboard.template.device.description',
                options,
                value: 0,
            },
        });
        if (dashboardId == null) {
            return;
        }
        const name = await this.dialog.open(SimpleInputDialog, {
            data: { title: 'dashboard.name' },
        });
        if (name == null) {
            return;
        }
        let content;
        if (dashboardId == 0) {
            // Empty template
            const datasourceData = DatasourceFactory.defaultDatasource(
                DatasourceType.DEVICE,
            ).build();
            datasourceData.settings['device'] = this.device.id;
            content = {
                version: 1,
                numColumns: 4,
                datasources: [datasourceData],
                panes: [],
            };
        } else {
            // Create from other dashboard
            const template = await this.api.dashboards.get(dashboardId);
            content = template.content;
            const datasource = content.datasources.find(
                (d) => d.type == DatasourceType.DEVICE,
            );
            datasource.settings['device'] = this.device.id;
        }
        try {
            const newDashboard = await this.api.dashboards.create({
                name,
                customerId: this.device.customerId,
                content,
                deviceIds: [this.device.id],
            });
            this.goToDashboard(newDashboard.id);
        } catch (e) {
            this.snackBar.open('dashboard.create_error_message');
        }
    }

    // The user wants to go to an existing board.
    confirm() {
        this.goToDashboard(this.id.value);
        this.dialogRef.close();
    }

    goToDashboard(id) {
        if (this.newTab.value) {
            window.open(`dashboards/${id}`, '_blank');
        } else {
            this.router.navigate([`../dashboards/${id}`], {
                relativeTo: this.activatedRoute,
            });
        }
    }
}
